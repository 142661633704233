import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  Available,
  Hiring,
  Main,
  OAuth,
  Schedule,
  Projects,
} from './components/pages';
import { useAuth } from './providers/Auth';
import { isEmpty } from 'ramda';

const withAuth = (Component: any, requireAuth = true) => (props: any) => {
  const authContext = useAuth();

  if (authContext.user === undefined) return null;

  if (requireAuth && isEmpty(authContext.user)) {
    return <Redirect to='/' />;
  } else if (!requireAuth && !isEmpty(authContext.user)) {
    return <Redirect to='/' />;
  }

  return <Component {...props} />;
};

export default () => {
  const AuthContext = useAuth();

  return (
    <Switch>
      <Route
        exact
        path='/'
        component={
          isEmpty(AuthContext.user) ? withAuth(Main, false) : withAuth(Projects)
        }
      />
      <Route exact path='/oauth' component={OAuth} />
      <Route exact path='/schedule' component={withAuth(Schedule)} />
      <Route exact path='/available' component={withAuth(Available)} />
      <Route exact path='/hiring' component={withAuth(Hiring)} />
    </Switch>
  );
};
