import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for PeopleAvailable
 */
export const PeopleAvailableStoreModel = types
  .model('PeopleAvailableStore', {
    people_available: types.maybe(types.map(types.frozen<Object>())),
  })
  .extend(EnvironmentExtension)
  .actions((self) => ({
    setPeopleBooking(people_available: any) {
      self.people_available = people_available;
    },
  }))
  .actions((self) => ({
    async getPeopleAvailable(
      startDate: string | null = null,
      endDate: string | null = null,
      billable: number = 1,
      name: string | null = null,
    ) {
      const peopleResponse = await self.environment.api.getPeopleAvailable(
        startDate,
        endDate,
        billable,
        name,
      );

      if (peopleResponse) {
        self.setPeopleBooking(peopleResponse);
        return peopleResponse;
      } else {
        self.setPeopleBooking({});
        return {};
      }
    },
  }));

type PeopleAvailableType = Instance<typeof PeopleAvailableStoreModel>;
export interface PeopleAvailable extends PeopleAvailableType {}

type PeopleAvailableSnapshotType = SnapshotOut<
  typeof PeopleAvailableStoreModel
>;
export interface PeopleAvailableSnapshot extends PeopleAvailableSnapshotType {}
