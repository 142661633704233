import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for Schedule
 */
export const ScheduleStoreModel = types
  .model('ScheduleStore', {
    people_global: types.maybe(types.map(types.frozen<Object>())),
  })
  .extend(EnvironmentExtension)
  .actions((self) => ({
    setSchedule(people_global: any) {
      self.people_global = people_global;
    },
  }))
  .actions((self) => ({
    async getSchedule(
      startDate: string | null = null,
      endDate: string | null = null,
      name: string | null = null,
    ) {
      const scheduleResponse = await self.environment.api.getSchedule(
        startDate,
        endDate,
        name,
      );

      if (scheduleResponse) {
        self.setSchedule(scheduleResponse);
        return scheduleResponse;
      } else {
        self.setSchedule({});
        return {};
      }
    },
  }));

type ScheduleType = Instance<typeof ScheduleStoreModel>;
export interface Schedule extends ScheduleType {}

type ScheduleSnapshotType = SnapshotOut<
  typeof ScheduleStoreModel
>;
export interface ScheduleSnapshot extends ScheduleSnapshotType {}
