import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';
import { CustomFieldStoreModel } from './CustomField';

/**
 * Store for ResourceType
 */
export const ResourceTypeStoreModel = types
  .model('ResourceTypeStore', {
    id: types.optional(types.integer, 0),
    name: types.optional(types.string, ''),
    url: types.optional(types.string, ''),
    human: types.optional(types.boolean, false),
    custom_fields: types.array(CustomFieldStoreModel),
    custom_attributes: types.array(types.optional(types.string, '')),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setResourceType(resourceType: { id?: any; name?: any; url?: any }) {
      if (resourceType) {
        self.id = resourceType.id;
        self.name = resourceType.name;
        self.url = resourceType.url;
      }
    },
  }))
  .actions((self) => ({
    async getResourceType(accountId: string, resourceTypeId: string) {
      const resourceTypeResponse = await self.environment.api.getResourceType(
        accountId,
        resourceTypeId
      );

      if (resourceTypeResponse) {
        self.setResourceType(resourceTypeResponse);

        return resourceTypeResponse;
      } else {
        self.setResourceType({});

        return {};
      }
    },
  }));

/**
 * Store for ResourceTypes
 */
export const ResourceTypesStoreModel = types
  .model('ResourceTypesStore', {
    resourceTypes: types.array(ResourceTypeStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setResourceTypes(resourceTypes: any) {
      self.resourceTypes = resourceTypes;
    },
  }))
  .actions((self) => ({
    async getResourceTypes(accountId: string) {
      const resourceTypesResponse = await self.environment.api.getResourceTypes(
        accountId
      );

      if (resourceTypesResponse) {
        self.setResourceTypes(resourceTypesResponse);

        return resourceTypesResponse;
      } else {
        self.setResourceTypes([]);

        return [];
      }
    },
  }));

type ResourceTypeType = Instance<typeof ResourceTypeStoreModel>;
export interface ResourceType extends ResourceTypeType {}

type ResourceTypeSnapshotType = SnapshotOut<typeof ResourceTypeStoreModel>;
export interface ResourceTypeSnapshot extends ResourceTypeSnapshotType {}
