import React, { useCallback, useEffect, useState, useContext } from 'react';
import { load, save } from '../utils/storage';
import { AuthStore, useStores } from '../models';

export {};
declare global {
  interface Window {
    AmazonCognitoAdvancedSecurityData: any;
  }
}

export const AuthContext = React.createContext<{
  user?: any | null;
  logout: (authStore: AuthStore) => void;
}>({
  user: {},
  logout: async () => {},
});

interface AuthProviderProps {}

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any | null>();
  const { authStore } = useStores();

  useEffect(() => {
    (async () => {
      let me: any = await load('me');

      if (!me) {
        me = {};
      }

      setUser(me);

      if (me.access_token) authStore.setAuth(me);
    })();
  }, [authStore]);

  return (
    <AuthContext.Provider
      value={{
        user,
        logout: async (authStore: AuthStore) => {
          await authStore.logout();
          setUser({});
          await save('me', {});
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
