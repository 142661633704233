import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for Client
 */
export const ClientStoreModel = types
  .model('ClientStore', {
    id: types.optional(types.integer, 0),
    creator_id: types.optional(types.integer, 0),
    name: types.optional(types.string, ''),
    notes: types.optional(types.string, ''),
    updated_at: types.optional(types.string, ''),
    url: types.optional(types.string, ''),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setClient(client: {
      id?: any;
      creator_id?: any;
      name?: any;
      notes?: any;
      updated_at?: any;
      url?: any;
    }) {
      if (client) {
        self.id = client.id;
        self.creator_id = client.creator_id;
        self.name = client.name;
        self.notes = client.notes;
        self.updated_at = client.updated_at;
        self.url = client.url;
      }
    },
  }))
  .actions((self) => ({
    async getClient(accountId: string, clientId: string) {
      const clientResponse = await self.environment.api.getClient(
        accountId,
        clientId
      );

      if (clientResponse) {
        self.setClient(clientResponse);

        return clientResponse;
      } else {
        self.setClient({});

        return {};
      }
    },
    async createClient(accountId: string, client: any) {
      const clientResponse = await self.environment.api.createClient(
        accountId,
        client
      );

      if (clientResponse) {
        self.setClient(clientResponse);

        return clientResponse;
      } else {
        self.setClient({});

        return {};
      }
    },
    async updateClient(accountId: string, clientId: string, client: any) {
      const clientResponse = await self.environment.api.updateClient(
        accountId,
        clientId,
        client
      );

      if (clientResponse) {
        self.setClient(clientResponse);

        return clientResponse;
      } else {
        self.setClient({});

        return {};
      }
    },
    async deleteClient(accountId: string, clientId: string) {
      const clientResponse = await self.environment.api.deleteClient(
        accountId,
        clientId
      );

      if (clientResponse) {
        self.setClient({});

        return {};
      } else {
        self.setClient({});

        return {};
      }
    },
  }));

/**
 * Store for Clients
 */
export const ClientsStoreModel = types
  .model('ClientsStore', {
    clients: types.array(ClientStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setClients(clients: any) {
      self.clients = clients;
    },
  }))
  .actions((self) => ({
    async getClients(accountId: string, limit = 50, offset = 0) {
      const clientsResponse = await self.environment.api.getClients(
        accountId,
        limit,
        offset
      );

      if (clientsResponse) {
        self.setClients(clientsResponse);

        return clientsResponse;
      } else {
        self.setClients([]);

        return [];
      }
    },
  }));

type ClientStoreType = Instance<typeof ClientStoreModel>;
export interface ClientStore extends ClientStoreType {}

type ClientStoreSnapshotType = SnapshotOut<typeof ClientStoreModel>;
export interface ClientStoreSnapshot extends ClientStoreSnapshotType {}
