import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for authenticating or signing up.
 */
export const AuthStoreModel = types
  .model('AuthStore', {
    access_token: types.optional(types.string, ''),
    refresh_token: types.optional(types.string, ''),
    token_type: types.optional(types.string, ''),
    expires_in: types.optional(types.integer, 0),
    account_id: types.optional(types.string, "")
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    async setAuth(auth: {
      access_token?: any;
      refresh_token?: any;
      token_type?: any;
      expires_in?: any;
      account_id?: any;
    }) {
      if (auth) {
        self.access_token = auth.access_token;
        self.refresh_token = auth.refresh_token;
        self.token_type = auth.token_type;
        self.expires_in = auth.expires_in;
        self.account_id = auth.account_id;
      }
    },
    setAccessToken(accessToken: string) {
      self.access_token = accessToken;
    },
    setRefreshToken(refreshToken: string) {
      self.refresh_token = refreshToken;
    },
  }))
  .actions((self) => ({
    async authToken(code: string) {
      const authResponse = await self.environment.api.getRGToken(code);

      if (authResponse) {
        self.setAuth(authResponse);

        return authResponse;
      } else {
        self.setAuth({});

        return {};
      }
    },
    async logout() {
      self.access_token = '';
      self.refresh_token = '';
      self.token_type = '';
      self.expires_in = 0;
    },
  }));
type AuthStoreType = Instance<typeof AuthStoreModel>;
export interface AuthStore extends AuthStoreType {}

type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>;
export interface AuthStoreSnapshot extends AuthStoreSnapshotType {}
