import {
  API_URL,
  CLIENT_ID,
  CLIENT_SECRET,
  REDIRECT_URI,
  RG_API_URL,
} from '../../config/env';

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  /**
   * The URL of the RG api.
   */
  rg_url: string;

  /**
   * The URL of the api.
   */
  url: string;

  /**
   * The RG client id.
   */
  client_id: string;

  /**
   * The RG client secret.
   */
  client_secret: string;

  /**
   * The RG redirect uri.
   */
  redirect_uri: string;

  /**
   * Milliseconds before timeout
   */
  timeout: number;
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL || 'http://localhost:3000',
  rg_url: RG_API_URL || 'https://localhost',
  client_id: CLIENT_ID,
  client_secret: CLIENT_SECRET,
  redirect_uri: REDIRECT_URI,
  timeout: 120000,
};
