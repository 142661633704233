import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';
import { CustomFieldOptionStoreModel } from './CustomFieldOption';

/**
 * Store for CustomField
 */
export const CustomFieldStoreModel = types
  .model('CustomFieldStore', {
    id: types.optional(types.integer, 0),
    name: types.optional(types.string, ''),
    required: types.optional(types.boolean, false),
    custom_field_options: types.array(CustomFieldOptionStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setCustomField(customField: any) {
      self.id = customField.id;
      self.name = customField.name;
      self.required = customField.required;
      self.custom_field_options = customField.custom_field_options;
    },
  }));

type CustomFieldStoreType = Instance<typeof CustomFieldStoreModel>;
export interface CustomFieldStore extends CustomFieldStoreType {}

type CustomFieldStoreSnapshotType = SnapshotOut<typeof CustomFieldStoreModel>;
export interface CustomFieldStoreSnapshot
  extends CustomFieldStoreSnapshotType {}
