import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';
import { TimezoneStoreModel } from './Timezone';

/**
 * Store for Me
 */
export const MeStoreModel = types
  .model('MeStore', {
    id: types.optional(types.integer, 0),
    first_name: types.optional(types.string, ''),
    last_name: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    image: types.optional(types.string, ''),
    timezone: types.maybe(TimezoneStoreModel),
    last_login_at: types.optional(types.string, ''),
    last_logout_at: types.optional(types.string, ''),
    last_activity_at: types.optional(types.string, ''),
    activation_state: types.optional(types.string, ''),
    created_at: types.optional(types.string, ''),
    updated_at: types.optional(types.string, ''),
    last_product_update_read_at: types.optional(types.string, ''),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    async setMe(me: {
      id?: any;
      first_name?: any;
      last_name?: any;
      email?: any;
      image?: any;
      timezone?: any;
      last_login_at?: any;
      last_logout_at?: any;
      last_activity_at?: any;
      activation_state?: any;
      created_at?: any;
      updated_at?: any;
      last_product_update_read_at?: any;
    }) {
      if (me) {
        self.id = me.id;
        self.first_name = me.first_name;
        self.last_name = me.last_name;
        self.email = me.email;
        self.image = me.image;
        self.timezone = me.timezone;
        self.last_login_at = me.last_login_at;
        self.last_logout_at = me.last_logout_at;
        self.last_activity_at = me.last_activity_at;
        self.activation_state = me.activation_state;
        self.created_at = me.created_at;
        self.updated_at = me.updated_at;
        self.last_product_update_read_at = me.last_product_update_read_at;
      }
    },
  }))
  .actions((self) => ({
    async getMe() {
      const meResponse = await self.environment.api.getMe();

      if (meResponse) {
        self.setMe(meResponse);

        return meResponse;
      } else {
        self.setMe({});

        return {};
      }
    },
  }));
type MeStoreType = Instance<typeof MeStoreModel>;
export interface MeStore extends MeStoreType {}

type MeStoreSnapshotType = SnapshotOut<typeof MeStoreModel>;
export interface MeStoreSnapshot extends MeStoreSnapshotType {}
