import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from '@material-ui/styles';
import Theme from './Theme';

console.log(
  '\x20\x20\x20\x5f\x5f\x5f\x20\x20\x5f\x5f\x5f\x5f\x20\x5f\x5f\x5f\x5f\x5f\x20\x5f\x20\x5f\x5f\x20\x20\x5f\x5f\x20\x20\x5f\x5f\x5f\x20\x20\x0a\x20\x20\x2f\x20\x5f\x20\x5c\x7c\x20\x20\x5f\x20\x5c\x5f\x20\x20\x20\x5f\x28\x5f\x29\x20\x20\x5c\x2f\x20\x20\x7c\x2f\x20\x5f\x20\x5c\x20\x0a\x20\x7c\x20\x7c\x20\x7c\x20\x7c\x20\x7c\x5f\x29\x20\x7c\x7c\x20\x7c\x20\x7c\x20\x7c\x20\x7c\x5c\x2f\x7c\x20\x7c\x20\x7c\x20\x7c\x20\x7c\x0a\x20\x7c\x20\x7c\x5f\x7c\x20\x7c\x20\x20\x5f\x5f\x2f\x20\x7c\x20\x7c\x20\x7c\x20\x7c\x20\x7c\x20\x20\x7c\x20\x7c\x20\x7c\x5f\x7c\x20\x7c\x0a\x20\x20\x5c\x5f\x5f\x5f\x2f\x7c\x5f\x7c\x20\x20\x20\x20\x7c\x5f\x7c\x20\x7c\x5f\x7c\x5f\x7c\x20\x20\x7c\x5f\x7c\x5c\x5f\x5f\x5f\x2f\x20\x0a\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x20\x0a'
);

ReactDOM.render(
  <ThemeProvider theme={Theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
