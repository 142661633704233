import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for Booker
 */
export const BookerStoreModel = types
  .model('BookerStore', {
    id: types.optional(types.integer, 0),
    color: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setBooker(booker: {
      id: number;
      color: string;
      email: string;
      name: string;
    }) {
      self.id = booker.id;
      self.color = booker.color;
      self.email = booker.email;
      self.name = booker.name;
    },
  }));

type BookerStoreType = Instance<typeof BookerStoreModel>;
export interface BookerStore extends BookerStoreType {}

type BookerStoreSnapshotType = SnapshotOut<typeof BookerStoreModel>;
export interface BookerStoreSnapshot extends BookerStoreSnapshotType {}
