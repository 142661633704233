import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';
import { BookerStoreModel } from './Booker';
import { DurationStoreModel } from './Duration';

/**
 * Store for Booking
 */
export const BookingStoreModel = types
  .model('BookingStore', {
    id: types.optional(types.integer, 0),
    client_id: types.optional(types.integer, 0),
    project_id: types.optional(types.integer, 0),
    resource_id: types.optional(types.integer, 0),
    notes: types.optional(types.string, ''),
    details: types.optional(types.string, ''),
    start_date: types.optional(types.string, ''),
    end_date: types.optional(types.string, ''),
    billable: types.optional(types.boolean, false),
    refreshable: types.optional(types.boolean, false),
    created_at: types.optional(types.string, ''),
    updated_at: types.optional(types.string, ''),
    booker: types.maybe(BookerStoreModel),
    durations: types.array(DurationStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setBooking(booking: {
      id?: any;
      client_id?: any;
      project_id?: any;
      resource_id?: any;
      notes?: any;
      details?: any;
      start_date?: any;
      end_date?: any;
      billable?: any;
      refreshable?: any;
      created_at?: any;
      updated_at?: any;
      booker?: any;
      durations?: any;
    }) {
      self.id = booking.id;
      self.client_id = booking.client_id;
      self.project_id = booking.project_id;
      self.resource_id = booking.resource_id;
      self.notes = booking.notes;
      self.details = booking.details;
      self.start_date = booking.start_date;
      self.end_date = booking.end_date;
      self.billable = booking.billable;
      self.refreshable = booking.refreshable;
      self.created_at = booking.created_at;
      self.updated_at = booking.updated_at;
      self.booker = booking.booker;
      self.durations = booking.durations;
    },
  }))
  .actions((self) => ({
    async getBooking(accountId: string, bookingId: string) {
      const bookingResponse = await self.environment.api.getBooking(
        accountId,
        bookingId
      );

      if (bookingResponse) {
        self.setBooking(bookingResponse);

        return bookingResponse;
      } else {
        self.setBooking({});

        return {};
      }
    },
    async createBooking(accountId: string, booking: any) {
      const bookingResponse = await self.environment.api.createBooking(
        accountId,
        booking
      );

      if (bookingResponse) {
        self.setBooking(bookingResponse);

        return bookingResponse;
      } else {
        self.setBooking({});

        return {};
      }
    },
    async updateBooking(accountId: string, bookingId: string, booking: any) {
      const bookingResponse = await self.environment.api.updateBooking(
        accountId,
        bookingId,
        booking
      );

      if (bookingResponse) {
        self.setBooking(bookingResponse);

        return bookingResponse;
      } else {
        self.setBooking({});

        return {};
      }
    },
    async deleteBooking(accountId: string, bookingId: string) {
      const bookingResponse = await self.environment.api.deleteBooking(
        accountId,
        bookingId
      );

      if (bookingResponse) {
        self.setBooking({});

        return {};
      } else {
        self.setBooking({});

        return {};
      }
    },
  }));

/**
 * Store for Bookings
 */
export const BookingsStoreModel = types
  .model('BookingsStore', {
    bookings: types.array(BookingStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setBookings(bookings: any) {
      self.bookings = bookings;
    },
  }))
  .actions((self) => ({
    async getBookings(accountId: string) {
      const bookingsResponse = await self.environment.api.getBookings(
        accountId
      );

      if (bookingsResponse) {
        self.setBookings(bookingsResponse);

        return bookingsResponse;
      } else {
        self.setBookings([]);

        return [];
      }
    },
  }));

type BookingStoreType = Instance<typeof BookingStoreModel>;
export interface BookingStore extends BookingStoreType {}

type BookingStoreSnapshotType = SnapshotOut<typeof BookingStoreModel>;
export interface BookingStoreSnapshot extends BookingStoreSnapshotType {}
