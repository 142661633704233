import { createMuiTheme } from '@material-ui/core/styles';
import { amber, blue, grey, red } from '@material-ui/core/colors';
import common from '@material-ui/core/colors/common';

import './assets/scss/styles.scss';

const defaultTheme = createMuiTheme();

const Theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#0483aa',
      light: '#369BBB',
      dark: '#025B76',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#2f2f2f',
      light: '#585858',
      dark: '#202020',
      contrastText: '#FFFFFF',
    },
    background: {
      paper: '#FFFFFF',
      default: '#FAFAFA',
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontSize: 16,
    button: {
      fontSize: '1rem',
    },
    caption: {
      fontSize: '0.75rem',
    },
    h1: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.375rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '1rem',
    },
    subtitle2: {
      fontSize: '1rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '1rem',
    },
    useNextVariants: true,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            color: common.white,
          },
        },
        sizeLarge: {
          fontSize: '0.938',
        },
        outlined: {
          border: '1px solid white',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          '& .MuiIconButton-root': {
            padding: 0,
          },
        },
      },
    },
  },
});

export default Theme;
