import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';
import { OwnerStoreModel } from './Owner';

/**
 * Store for Account
 */
export const AccountStoreModel = types
  .model('AccountStore', {
    id: types.optional(types.integer, 0),
    name: types.optional(types.string, ''),
    subdomain: types.optional(types.string, ''),
    rootdomain: types.optional(types.string, ''),
    url: types.optional(types.string, ''),
    created_at: types.optional(types.string, ''),
    updated_at: types.optional(types.string, ''),
    owner: types.maybe(OwnerStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setAccount(account: {
      id?: any;
      name?: any;
      subdomain?: any;
      rootdomain?: any;
      url?: any;
      created_at?: any;
      updated_at?: any;
    }) {
      self.id = account.id;
      self.name = account.name;
      self.subdomain = account.subdomain;
      self.rootdomain = account.rootdomain;
      self.url = account.url;
      self.created_at = account.created_at;
      self.updated_at = account.updated_at;
    },
  }))
  .actions((self) => ({
    async getAccount(accountId: string) {
      const accountResponse = await self.environment.api.getAccount(accountId);

      if (accountResponse) {
        self.setAccount(accountResponse);

        return accountResponse;
      } else {
        self.setAccount({});

        return {};
      }
    },
  }));

/**
 * Store for Accounts
 */
export const AccountsStoreModel = types
  .model('AccountsStore', {
    accounts: types.optional(types.array(AccountStoreModel), []),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setAccounts(accounts: any) {
      self.accounts = accounts.accounts;
    },
  }))
  .actions((self) => ({
    async getAccounts() {
      const accountsResponse = await self.environment.api.getAccounts();

      if (accountsResponse) {
        self.setAccounts(accountsResponse);

        return accountsResponse;
      } else {
        self.setAccounts([]);

        return [];
      }
    },
  }));

type AccountStoreType = Instance<typeof AccountStoreModel>;
export interface AccountStore extends AccountStoreType {}

type AccountStoreSnapshotType = SnapshotOut<typeof AccountStoreModel>;
export interface AccountStoreSnapshot extends AccountStoreSnapshotType {}

type AccountsStoreType = Instance<typeof AccountsStoreModel>;
export interface AccountsStore extends AccountsStoreType {}

type AccountsStoreSnapshotType = SnapshotOut<typeof AccountsStoreModel>;
export interface AccountsStoreSnapshot extends AccountsStoreSnapshotType {}
