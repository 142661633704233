import React, { useState } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({}));

export default () => {
  const classes = useStyles();

  return (
    <Container disableGutters={true} maxWidth={false}>
      <Container maxWidth='lg'>
        <Grid
          alignItems='center'
          container
          justifyContent='center'
          spacing={10}
        >
          <Grid item xs={12}>
            <Typography variant='h1'>ResourceGuru Enhanced View</Typography>
            <Typography variant='h2' gutterBottom>
              Welcome to the dashboard
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};
