import React, { useEffect, useState } from 'react';
import { CircularProgress, FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface Props {
  formControlClass?: string | undefined;
  autoCompleteClass?: string | undefined;
  clearOnBlur?: boolean | undefined;
  getSearch: (search: string) => any;
  handleSelectedOption: (data: any) => void;
  handleCloseChangeBlur?: () => any | undefined;
  handleChangeClear?: () => any | undefined;
  inputClass?: string | undefined;
  textFieldClass?: string | undefined;
  textFieldLabel?: any | undefined;
  textFieldVariant?: any | undefined;
  textFieldPlaceholder?: any | undefined;
  required?: boolean | undefined;
  startingOptions?: any | undefined;
  value?: any | undefined;
}

let timeout: any;

export default (props: Props) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [changed, setChanged] = useState(false);

  async function doSearch(e: any) {
    setOptions([]);
    setOpen(false);
    setLoading(true);
    setChanged(true);
    const target = e.target;

    clearTimeout(timeout);
    timeout = setTimeout(
      async () => {
        let results = await props.getSearch(target.value);

        setLoading(false);
        setOpen(true);

        if (results) {
          setOptions(Object.keys(results).map((key) => results[key]) as any[]);
        }
      },
      500,
      target
    );
  }

  useEffect(() => {
    (async () => {
      if (!open) {
        setOptions([]);
        setLoading(true);
        await setStartingOptions();
      }

      async function setStartingOptions() {
        const opts =
          typeof props.startingOptions === 'function'
            ? await props.startingOptions()
            : [];

        setLoading(false);

        if (opts && opts.length > 0) {
          setOptions(Object.keys(opts).map((key) => opts[key]) as any[]);
        }
      }
    })();
  }, [open, selectedOption, props]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = async (event: React.ChangeEvent<{}>, reason: any) => {
    if (reason === 'blur' && props.handleCloseChangeBlur && changed) {
      await props.handleCloseChangeBlur();
      setChanged(false);
    }
    setOpen(false);
  };

  const handleGetOptionSelected = (data: any) => {
    setSelectedOption(data ? data.name : '');
    props.handleSelectedOption(data);
  };

  return (
    <FormControl
      className={props.formControlClass}
      required={props.required}
      fullWidth
      margin='none'
    >
      <Autocomplete
        className={`autocomplete-combobox ${props.autoCompleteClass}`}
        fullWidth
        open={open}
        clearOnBlur={props.clearOnBlur}
        onOpen={handleOpen}
        onClose={handleClose}
        getOptionSelected={(option, value) => option === value}
        getOptionLabel={(option) => (option ? option : '')}
        value={props.value}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.textFieldLabel}
            fullWidth
            required={props.required}
            onChange={doSearch}
            placeholder={props.textFieldPlaceholder}
            className={props.textFieldClass}
            variant={
              props.textFieldVariant ? props.textFieldVariant : 'standard'
            }
            InputProps={{
              ...params.InputProps,
              className: props.inputClass,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        onChange={(event: any, data: any, reason: any) => {
          if (reason === 'clear' && props.handleChangeClear && changed) {
            props.handleChangeClear();
            setChanged(false);
          }
          handleGetOptionSelected(data);
        }}
      />
    </FormControl>
  );
};
