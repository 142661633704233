import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for Hiring
 */
export const HiringStoreModel = types
  .model('HiringStore', {
    hiring: types.maybe(types.map(types.frozen<Object>())),
  })
  .extend(EnvironmentExtension)
  .actions((self) => ({
    setHiring(hiring: any) {
      self.hiring = hiring;
    },
  }))
  .actions((self) => ({
    async getHiring(
      startDate: string | null = null,
      endDate: string | null = null
    ) {
      const hiringResponse = await self.environment.api.getHiring(
        startDate,
        endDate,
      );

      if (hiringResponse) {
        self.setHiring(hiringResponse);
        return hiringResponse;
      } else {
        self.setHiring({});
        return {};
      }
    },
  }));

type HiringType = Instance<typeof HiringStoreModel>;
export interface Hiring extends HiringType {}

type HiringSnapshotType = SnapshotOut<
  typeof HiringStoreModel
>;
export interface HiringSnapshot extends HiringSnapshotType {}
