import React, { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { useStores } from '../../../models';
import { isEmpty } from 'ramda';
import { toJS } from 'mobx';
import { ComboBox } from '../../atoms';
import { PageLoader } from '../../organisms';
import moment from 'moment';
import {
  timeConvertHoursToPercent,
  timeConvertMinutesToHours,
} from '../../../helpers/Data';
import { getBusinessHoursForMonth } from '../../../utils/BusinessDays';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    overflow: 'hidden',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  projectName: {
    alignItems: 'flex-end',
    display: 'flex',
  },
  projectInternal: {
    backgroundColor: '#eceef3', // blue
    display: 'inline-block',
    height: 30,
    marginRight: theme.spacing(2),
    width: 30,
  },
  projectProbableFunding: {
    backgroundColor: '#ffa96f', // orange
    display: 'inline-block',
    height: 30,
    marginRight: theme.spacing(2),
    width: 30,
  },
  projectFunded: {
    backgroundColor: '#a6fa85', // green
    display: 'inline-block',
    height: 30,
    marginRight: theme.spacing(2),
    width: 30,
  },
  projectNotFunded: {
    backgroundColor: '#fc8e89', // red
    display: 'inline-block',
    height: 30,
    marginRight: theme.spacing(2),
    width: 30,
  },
  resourcesNeeded: {
    color: red[800],
    fontWeight: 700,
  },
}));

const HtmlTooltip = withStyles((theme: Theme) => ({
  arrow: {
    color: '#2f2f2f',
  },
  tooltip: {
    backgroundColor: '#2f2f2f',
  },
}))(Tooltip);

export default () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [showFundedOnly, setShowFundedOnly] = useState(false);
  const [updateMonths, setUpdateMonths] = useState(false);
  const [data, setData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [searchData, setSearchData] = useState('');
  const [updateData, setUpdateData] = useState(false);
  const defaultMonthsDisplay = 6;
  const [defaultFilters, setDefaultFilters] = useState<any>({
    currentDate: moment().format('YYYY-MM-DD'),
    months: defaultMonthsDisplay,
    monthList: [],
  });
  const { projectGlobalStore } = useStores();
  const [pendingSave, setPendingSave] = useState(false);

  const getProjectInfo = (
    indexProject: any,
    monthDate: any,
    workHours: any
  ) => {
    const projects = Object.entries(indexProject);

    let projectCheck = false;
    let fundedStatus: any = null;
    let minutesBooked: any = 0;
    let resourcesNeeded: any = '--';
    let resourcesBooked: any = '--';
    let people: any = {};
    people.children = [];

    const projectArray: any = projects.filter((item: any) => {
      for (var key in projects) {
        if (item[key] === 'project_name') {
          return false;
        }
      }

      return true;
    });

    fundedStatus = projects.filter((item: any) => {
      for (var key in projects) {
        if (item[key] === 'project_color') {
          return item[key] === 'project_color';
        }
      }

      return false;
    });

    const projectArrayFiltered: any = projectArray.filter((item: any) =>
      moment(item[0]).isSame(monthDate, 'month')
    );

    if (projectArrayFiltered[0]) {
      const allProjects: any = Object.entries(projectArrayFiltered[0][1]);

      allProjects.forEach((projects: any) => {
        const allPeople: any = Object.entries(projects[1]);

        resourcesNeeded = projectArrayFiltered[0][1].percent_people;
        resourcesBooked = projectArrayFiltered[0][1].percent_total;

        allPeople.forEach((person: any) => {
          const totalMinutes = parseInt(person[1].duration);
          minutesBooked += totalMinutes;

          if (minutesBooked > 0) {
            people.children.push({
              name: person[1].resource_name,
              hours: timeConvertMinutesToHours(totalMinutes),
              percentage: timeConvertHoursToPercent(
                timeConvertMinutesToHours(totalMinutes),
                workHours
              ),
            });
          }
        });
      });

      projectCheck = true;
    }

    return (
      <TableCell align='center' padding='default'>
        {projectCheck ? (
          <HtmlTooltip
            arrow
            title={
              <List dense>
                {people.children.map(
                  (
                    resourse: {
                      name: React.ReactNode;
                      percentage: React.ReactNode;
                    },
                    index: string | number | null | undefined
                  ) => {
                    return (
                      <ListItem key={index} dense>
                        <ListItemText>
                          {resourse.name} -{' '}
                          <em>({resourse.percentage + '%'})</em>
                        </ListItemText>
                      </ListItem>
                    );
                  }
                )}
              </List>
            }
          >
            <span
              className={
                resourcesNeeded !== resourcesBooked
                  ? `booking-hours ${classes.resourcesNeeded}`
                  : 'booking-hours'
              }
            >
              {resourcesNeeded} / {resourcesBooked}
            </span>
          </HtmlTooltip>
        ) : (
          <span
            className={
              resourcesNeeded !== resourcesBooked
                ? `booking-hours ${classes.resourcesNeeded}`
                : 'booking-hours'
            }
          >
            {resourcesNeeded} / {resourcesBooked}
          </span>
        )}
      </TableCell>
    );
  };

  // #eceef3 (blue)
  // #ffa96f (orange)
  // #a6fa85 (green)
  // #fc8e89 (red)
  const mapColorToStatus = (colorCode: any) =>
    colorCode === '#eceef3'
      ? classes.projectInternal
      : colorCode === '#ffa96f'
      ? classes.projectProbableFunding
      : colorCode === '#fc8e89'
      ? classes.projectNotFunded
      : colorCode === '#a6fa85'
      ? classes.projectFunded
      : '';

  const mapColorToFunding = (colorCode: any) =>
    colorCode === '#a6fa85' ? 'funded' : 'not-funded';

  const getMonthList = useCallback(() => {
    let monthList: any = {};
    monthList.children = [];

    for (let i = 0; i < toJS(defaultFilters.months); i++) {
      const monthShortName = moment().add(i, 'month').format('MMM');
      const monthNumber = moment().add(i, 'month').format('MM');
      const year = moment().add(i, 'month').format('YYYY');
      const yearMonth = moment().add(i, 'month').format('YYYY-MM');

      monthList.children.push({
        name: monthShortName,
        date: yearMonth,
        workHours: getBusinessHoursForMonth(Number(year), Number(monthNumber)),
      });
    }

    setDefaultFilters({
      ...defaultFilters,
      monthList,
    });
  }, [defaultFilters]);

  const getApiData = async () => {
    let startMonth: any = undefined;
    let endMonth: any = undefined;

    if (defaultFilters.monthList.children) {
      startMonth = moment(defaultFilters.monthList.children[0].date)
        .startOf('month')
        .format('YYYY-MM-DD');

      endMonth = moment(
        defaultFilters.monthList.children[
          defaultFilters.monthList.children.length - 1
        ].date
      )
        .endOf('month')
        .format('YYYY-MM-DD');
    }

    const results = await projectGlobalStore.getProjectGlobal(
      startMonth,
      endMonth,
      searchData
    );

    // TODO: SET ERRORS
    if (results.kind !== 'ok') {
    }

    // console.log('***', results.resourceType.project_global);
    setData(Object.entries(results.resourceType.project_global));
  };

  const getProjects = async () => {
    await getApiData();
    getMonthList();
  };

  useEffect(() => {
    (async () => {
      if (loading) {
        await getProjects();
        setLoading(false);
      }
    })();
  }, [loading]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const filterDataArray: any = [];

      data.forEach((filter: any[]) => {
        filterDataArray.push(filter[0]);
      });

      setFilterData(filterDataArray);
    }
  }, [data]);

  // useEffect(() => {
  //   if (!isEmpty(data))
  //     console.log('***', 'useEffect || filterData', filterData);
  // }, [filterData]);

  const handleFundedChange = () => {
    setShowFundedOnly(!showFundedOnly);
  };

  const handleMonthCountChange = (
    event: React.SyntheticEvent,
    newValue: number | number[]
  ) => {
    if (defaultFilters.months !== newValue && typeof newValue === 'number') {
      setDefaultFilters({
        ...defaultFilters,
        months: newValue,
      });

      setUpdateMonths(true);
    }
  };

  const handleSearchData = (search: string) => {
    setUpdateData(true);
    setSearchData(search);
    return filterData;
  };

  useEffect(() => {
    if (updateMonths) {
      getMonthList();
      setUpdateMonths(false);
      setUpdateData(true);
    }
  }, [updateMonths]);

  useEffect(() => {
    (async () => {
      if (updateData) {
        await getApiData();
      }
    })();
  }, [defaultFilters.monthList.children]);

  useEffect(() => {
    (async () => {
      if (updateData) {
        await getApiData();
      }
    })();
  }, [searchData]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Container disableGutters={true} maxWidth={false}>
      <Container maxWidth='lg'>
        <Grid
          alignItems='center'
          container
          justifyContent='center'
          spacing={10}
        >
          <Grid item xs={12}>
            <Typography variant='h1' gutterBottom>
              Projects
            </Typography>
            <Typography variant='subtitle1'>
              This report displays resources allocated vs resources needed for a
              given project. Hover on a comparison for a detailed breakdown.
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <ComboBox
              getSearch={handleSearchData}
              handleCloseChangeBlur={() => {
                setSearchData('');
              }}
              handleChangeClear={() => {
                setSearchData('');
              }}
              startingOptions={filterData}
              textFieldLabel='Search by project name'
              handleSelectedOption={(filterData) => {
                setSearchData(filterData ? filterData : '');
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <Typography id='continuous-slider' gutterBottom>
              Number of months to display:
            </Typography>

            <Slider
              defaultValue={defaultMonthsDisplay}
              aria-labelledby='month-display-slider'
              valueLabelDisplay='auto'
              onChangeCommitted={handleMonthCountChange}
              marks
              step={1}
              min={1}
              max={12}
            />
          </Grid>

          <Grid item xs={3}>
            <FormGroup row>
              <FormControlLabel
                value='end'
                control={
                  <Checkbox
                    checked={showFundedOnly}
                    onChange={handleFundedChange}
                    color='primary'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label='Funded Only'
                labelPlacement='end'
              />
            </FormGroup>
          </Grid>

          {!isEmpty(data) && (
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <TableContainer>
                  <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='left' padding='default'>
                          <Typography variant='h6' component='div'>
                            Project Name
                          </Typography>
                        </TableCell>
                        {toJS(defaultFilters.monthList.children).map(
                          (
                            month: { name: React.ReactNode },
                            index: string | number | null | undefined
                          ) => {
                            return (
                              <TableCell
                                align='center'
                                padding='default'
                                key={index}
                              >
                                <Typography variant='h6' component='div'>
                                  {month.name}
                                </Typography>
                              </TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {data.map((project: any, projectIndex: any) => {
                        return (
                          <TableRow
                            key={projectIndex}
                            className={
                              mapColorToFunding(project[1].project_color) !==
                                'funded' && showFundedOnly
                                ? 'hidden'
                                : ''
                            }
                            data-name={project[1].project_name}
                          >
                            <TableCell>
                              <Typography
                                variant='subtitle1'
                                component='div'
                                className={classes.projectName}
                              >
                                <span
                                  className={mapColorToStatus(
                                    project[1].project_color
                                  )}
                                />
                                {project[1].project_name}
                              </Typography>
                            </TableCell>

                            {toJS(defaultFilters.monthList.children).map(
                              (
                                month: {
                                  date: React.ReactNode;
                                  workHours: React.ReactNode;
                                },
                                monthIndex: string | number | null | undefined
                              ) => {
                                return (
                                  <React.Fragment key={monthIndex}>
                                    {getProjectInfo(
                                      project[1],
                                      month.date,
                                      month.workHours
                                    )}
                                  </React.Fragment>
                                );
                              }
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Container>
    </Container>
  );
};
