import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { AccountStoreModel, AccountsStoreModel } from './Account';
import { AuthStoreModel } from './Auth';
import { BookerStoreModel } from './Booker';
import { BookingStoreModel } from './Booking';
import { ClientStoreModel } from './Client';
import { CustomFieldStoreModel } from './CustomField';
import { CustomFieldOptionStoreModel } from './CustomFieldOption';
import { DowntimeStoreModel } from './Downtime';
import { DowntimeTypeStoreModel } from './DowntimeType';
import { DurationStoreModel } from './Duration';
import { HiringStoreModel } from './Hiring';
import { MeStoreModel } from './Me';
import { OwnerStoreModel } from './Owner';
import { PeopleStoreModel } from './People';
import { PeopleAvailableStoreModel } from './PeopleAvailable';
import { PeopleBookingStoreModel } from './PeopleBooking';
import { ProjectStoreModel, ProjectsStoreModel } from './Project';
import { ProjectGlobalStoreModel } from './ProjectGlobal';
import { ResourceStoreModel } from './Resource';
import { ResourceTypeStoreModel } from './ResourceType';
import { ScheduleStoreModel } from './Schedule';
import { TimezoneStoreModel } from './Timezone';

/**
 * A RootStore model.
 */
export const Store = types.model('RootStore').props({
  accountStore: types.optional(AccountStoreModel, {}),
  accountsStore: types.optional(AccountsStoreModel, {}),
  authStore: types.optional(AuthStoreModel, {}),
  clientStore: types.optional(ClientStoreModel, {}),
  bookerStore: types.optional(BookerStoreModel, {}),
  bookingStore: types.optional(BookingStoreModel, {}),
  customFieldStore: types.optional(CustomFieldStoreModel, {}),
  customFieldOptionStore: types.optional(CustomFieldOptionStoreModel, {}),
  downtimeStore: types.optional(DowntimeStoreModel, {}),
  downtimeTypeStore: types.optional(DowntimeTypeStoreModel, {}),
  durationStore: types.optional(DurationStoreModel, {}),
  hiringStore: types.optional(HiringStoreModel, {}),
  meStore: types.optional(MeStoreModel, {}),
  ownerStore: types.optional(OwnerStoreModel, {}),
  peopleStore: types.optional(PeopleStoreModel, {}),
  peopleAvailableStore: types.optional(PeopleAvailableStoreModel, {}),
  peopleBookingStore: types.optional(PeopleBookingStoreModel, {}),
  projectStore: types.optional(ProjectStoreModel, {}),
  projectsStore: types.optional(ProjectsStoreModel, {}),
  projectGlobalStore: types.optional(ProjectGlobalStoreModel, {}),
  resourceStore: types.optional(ResourceStoreModel, {}),
  resourceTypeStore: types.optional(ResourceTypeStoreModel, {}),
  scheduleStore: types.optional(ScheduleStoreModel, {}),
  timezoneStore: types.optional(TimezoneStoreModel, {}),
});

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof Store> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof Store> {}
