import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for Downtime
 */
export const DowntimeStoreModel = types
  .model('DowntimeStore', {
    id: types.optional(types.integer, 0),
    account_id: types.optional(types.integer, 0),
    created_at: types.optional(types.string, ''),
    creator_id: types.optional(types.integer, 0),
    booker_id: types.optional(types.integer, 0),
    deleted: types.optional(types.boolean, false),
    details: types.optional(types.string, ''),
    downtime_type_id: types.optional(types.integer, 0),
    end_time: types.optional(types.integer, 0),
    from: types.optional(types.string, ''),
    leave: types.optional(types.string, ''),
    resource_ids: types.array(types.optional(types.integer, 0)),
    start_time: types.optional(types.integer, 0),
    state: types.optional(types.string, ''),
    timezone: types.optional(types.string, ''),
    to: types.optional(types.string, ''),
    updated_at: types.optional(types.string, ''),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setDowntime(downtime: {
      id?: any;
      account_id?: any;
      created_at?: any;
      creator_id?: any;
      booker_id?: any;
      deleted?: any;
      details?: any;
      downtime_type_id?: any;
      end_time?: any;
      from?: any;
      leave?: any;
      resource_ids?: any;
      start_time?: any;
      state?: any;
      timezone?: any;
      to?: any;
      updated_at?: any;
    }) {
      if (downtime) {
        self.id = downtime.id;
        self.account_id = downtime.account_id;
        self.created_at = downtime.created_at;
        self.creator_id = downtime.creator_id;
        self.booker_id = downtime.booker_id;
        self.deleted = downtime.deleted;
        self.details = downtime.details;
        self.downtime_type_id = downtime.downtime_type_id;
        self.end_time = downtime.end_time;
        self.from = downtime.from;
        self.leave = downtime.leave;
        self.resource_ids = downtime.resource_ids;
        self.start_time = downtime.start_time;
        self.state = downtime.state;
        self.timezone = downtime.timezone;
        self.to = downtime.to;
        self.updated_at = downtime.updated_at;
      }
    },
  }))
  .actions((self) => ({
    async getDowntime(accountId: string, downtimeId: string) {
      const downtimeResponse = await self.environment.api.getDowntime(
        accountId,
        downtimeId
      );

      if (downtimeResponse) {
        self.setDowntime(downtimeResponse);

        return downtimeResponse;
      } else {
        self.setDowntime({});

        return {};
      }
    },
    async createDowntime(accountId: string, downtime: any) {
      const downtimeResponse = await self.environment.api.createDowntime(
        accountId,
        downtime
      );

      if (downtimeResponse) {
        self.setDowntime(downtimeResponse);

        return downtimeResponse;
      } else {
        self.setDowntime({});

        return {};
      }
    },
    async updateDowntime(accountId: string, downtimeId: string, downtime: any) {
      const downtimeResponse = await self.environment.api.updateDowntime(
        accountId,
        downtimeId,
        downtime
      );

      if (downtimeResponse) {
        self.setDowntime(downtimeResponse);

        return downtimeResponse;
      } else {
        self.setDowntime({});

        return {};
      }
    },
    async deleteDowntime(accountId: string, downtimeId: string) {
      const downtimeResponse = await self.environment.api.deleteBooking(
        accountId,
        downtimeId
      );

      if (downtimeResponse) {
        self.setDowntime({});

        return {};
      } else {
        self.setDowntime({});

        return {};
      }
    },
  }));

/**
 * Store for Downtimes
 */
export const DowntimesStoreModel = types
  .model('DowntimesStore', {
    downtimes: types.array(DowntimeStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setDowntimes(downtimes: any) {
      self.downtimes = downtimes;
    },
  }))
  .actions((self) => ({
    async getDowntimes(
      accountId: string,
      resource_ids = [],
      limit = 50,
      offset = 0,
      from = null,
      to = null
    ) {
      const downtimesResponse = await self.environment.api.getDowntimes(
        accountId,
        resource_ids,
        limit,
        offset,
        from,
        to
      );

      if (downtimesResponse) {
        self.setDowntimes(downtimesResponse);

        return downtimesResponse;
      } else {
        self.setDowntimes([]);

        return [];
      }
    },
  }));

type DowntimeStoreType = Instance<typeof DowntimeStoreModel>;
export interface DowntimeStore extends DowntimeStoreType {}

type DowntimeStoreSnapshotType = SnapshotOut<typeof DowntimeStoreModel>;
export interface DowntimeStoreSnapshot extends DowntimeStoreSnapshotType {}
