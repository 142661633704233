import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for Project
 */
export const ProjectStoreModel = types
  .model('ProjectStore', {
    id: types.optional(types.integer, 0),
    creator_id: types.optional(types.integer, 0),
    color: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    project_code: types.optional(types.string, ''),
    notes: types.optional(types.string, ''),
    default_billable: types.optional(types.boolean, true),
    updated_at: types.optional(types.string, ''),
    url: types.optional(types.string, ''),
    account_id: types.optional(types.integer, 0),
    client_id: types.optional(types.integer, 0),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setProject(project: {
      id?: any;
      creator_id?: any;
      color?: any;
      name?: any;
      project_code?: any;
      notes?: any;
      default_billable?: any;
      updated_at?: any;
      url?: any;
      account_id?: any;
      client_id?: any;
    }) {
      if (project) {
        self.id = project.id;
        self.creator_id = project.creator_id;
        self.color = project.color;
        self.name = project.name;
        self.project_code = project.project_code;
        self.notes = project.notes;
        self.default_billable = project.default_billable;
        self.updated_at = project.updated_at;
        self.url = project.url;
        self.account_id = project.account_id;
        self.client_id = project.client_id;
      }
    },
  }))
  .actions((self) => ({
    async getProject(accountId: string, projectId: string) {
      const projectResponse = await self.environment.api.getProject(
        accountId,
        projectId
      );

      if (projectResponse) {
        self.setProject(projectResponse);

        return projectResponse;
      } else {
        self.setProject({});

        return {};
      }
    },
    async createProject(accountId: string, project: any) {
      const projectResponse = await self.environment.api.createProject(
        accountId,
        project
      );

      if (projectResponse) {
        self.setProject(projectResponse);

        return projectResponse;
      } else {
        self.setProject({});

        return {};
      }
    },
    async updateProject(accountId: string, projectId: string, project: any) {
      const projectResponse = await self.environment.api.updateProject(
        accountId,
        projectId,
        project
      );

      if (projectResponse) {
        self.setProject(projectResponse);

        return projectResponse;
      } else {
        self.setProject({});

        return {};
      }
    },
    async deleteProject(accountId: string, projectId: string) {
      const projectResponse = await self.environment.api.deleteProject(
        accountId,
        projectId
      );

      if (projectResponse) {
        self.setProject({});

        return {};
      } else {
        self.setProject({});

        return {};
      }
    },
  }));

/**
 * Store for Projects
 */
export const ProjectsStoreModel = types
  .model('ProjectsStore', {
    projects: types.optional(types.array(ProjectStoreModel), []),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setProjects(projects: any) {
      self.projects = projects;
    },
  }))
  .actions((self) => ({
    async getProjects(accountId: string, limit = 50, offset = 0) {
      const projectsResponse = await self.environment.api.getProjects(
        accountId,
        limit,
        offset
      );

      console.log('***', 'projectsResponse', projectsResponse);

      // if (projectsResponse) {
      //   self.setProjects(projectsResponse);

      //   return projectsResponse;
      // } else {
      //   self.setProjects([]);

      //   return [];
      // }
    },
  }));

type ProjectStoreType = Instance<typeof ProjectStoreModel>;
export interface ProjectStore extends ProjectStoreType {}

type ProjectStoreSnapshotType = SnapshotOut<typeof ProjectStoreModel>;
export interface ProjectStoreSnapshot extends ProjectStoreSnapshotType {}

type ProjectsStoreType = Instance<typeof ProjectsStoreModel>;
export interface ProjectsStore extends ProjectsStoreType {}

type ProjectsStoreSnapshotType = SnapshotOut<typeof ProjectsStoreModel>;
export interface ProjectsStoreSnapshot extends ProjectsStoreSnapshotType {}
