import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';
import { DurationStoreModel } from './Duration';
import { ProjectStoreModel } from './Project';
import { load, save, remove } from '../../utils/storage';
import { isEmpty } from 'ramda';

/**
 * Store for PeopleBooking
 */
export const PeopleBookingStoreModel = types
  .model('PeopleBookingStore', {
    billable: types.optional(types.boolean, false),
    created_at: types.optional(types.string, ''),
    details: types.optional(types.string, ''),
    durations: types.array(DurationStoreModel),
    end_date: types.optional(types.string, ''),
    id: types.optional(types.integer, 0),
    notes: types.optional(types.string, ''),
    project: types.maybe(ProjectStoreModel),
    refreshable: types.optional(types.boolean, false),
    start_date: types.optional(types.string, ''),
    updated_at: types.optional(types.string, ''),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setPeopleBooking(booking: {
      billable?: any;
      created_at?: any;
      details?: any;
      durations?: any;
      end_date?: any;
      id?: any;
      notes?: any;
      project?: any;
      refreshable?: any;
      start_date?: any;
      updated_at?: any;
    }) {
      self.billable = booking.billable;
      self.created_at = booking.created_at;
      self.details = booking.details;
      self.durations = booking.durations;
      self.end_date = booking.end_date;
      self.id = booking.id;
      self.notes = booking.notes;
      self.project = booking.project;
      self.refreshable = booking.refreshable;
      self.start_date = booking.start_date;
      self.updated_at = booking.updated_at;
    },
  }))
  .actions((self) => ({
    async getPeople(storeBust?: boolean | undefined) {
      const peopleBooking = await load('peopleBooking');

      if (
        !isEmpty(peopleBooking) &&
        (storeBust === undefined || storeBust === false)
      ) {
        return peopleBooking;
      }

      const bookingResponse = await self.environment.api.getPeople();

      if (bookingResponse) {
        self.setPeopleBooking(bookingResponse);

        await save('peopleBooking', bookingResponse);

        return bookingResponse;
      } else {
        self.setPeopleBooking({});
        return {};
      }
    },
  }));

type PeopleBookingStoreType = Instance<typeof PeopleBookingStoreModel>;
export interface PeopleBookingStore extends PeopleBookingStoreType {}

type PeopleBookingStoreSnapshotType = SnapshotOut<
  typeof PeopleBookingStoreModel
>;
export interface BookingStoreSnapshot extends PeopleBookingStoreSnapshotType {}
