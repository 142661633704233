import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for DowntimeType
 */
export const DowntimeTypeStoreModel = types
  .model('DowntimeTypeStore', {
    id: types.optional(types.integer, 0),
    name: types.optional(types.string, ''),
    account_id: types.optional(types.integer, 0),
    created_at: types.optional(types.string, ''),
    updated_at: types.optional(types.string, ''),
    default: types.optional(types.boolean, false),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    async setDowntimeType(downtimeType: {
      id?: any;
      name?: any;
      account_id?: any;
      created_at?: any;
      updated_at?: any;
      default?: any;
    }) {
      if (downtimeType) {
        self.id = downtimeType.id;
        self.name = downtimeType.name;
        self.account_id = downtimeType.account_id;
        self.created_at = downtimeType.created_at;
        self.updated_at = downtimeType.updated_at;
        self.default = downtimeType.default;
      }
    },
  }))
  .actions((self) => ({
    async getDowntimeType(accountId: string) {
      const downtimeTypeResponse = await self.environment.api.getDowntimeEventTypes(
        accountId
      );

      if (downtimeTypeResponse) {
        self.setDowntimeType(downtimeTypeResponse);

        return downtimeTypeResponse;
      } else {
        self.setDowntimeType({});

        return {};
      }
    },
  }));
type DowntimeTypeStoreType = Instance<typeof DowntimeTypeStoreModel>;
export interface DowntimeTypeStore extends DowntimeTypeStoreType {}

type DowntimeTypeStoreSnapshotType = SnapshotOut<typeof DowntimeTypeStoreModel>;
export interface DowntimeTypeStoreSnapshot
  extends DowntimeTypeStoreSnapshotType {}
