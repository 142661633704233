import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for ProjectGlobal
 */
export const ProjectGlobalStoreModel = types
  .model('ProjectGlobalStore', {
    project_global: types.maybe(types.map(types.frozen<Object>())),
  })
  .extend(EnvironmentExtension)
  .actions((self) => ({
    setProjectGlobal(project_global: any) {
      self.project_global = project_global;
    },
  }))
  .actions((self) => ({
    async getProjectGlobal(
      startDate: string | null = null,
      endDate: string | null = null,
      name: string | null = null,
    ) {
      const projectGlobalResponse = await self.environment.api.getProjectGlobal(
        startDate,
        endDate,
        name,
      );

      if (projectGlobalResponse) {
        self.setProjectGlobal(projectGlobalResponse);
        return projectGlobalResponse;
      } else {
        self.setProjectGlobal({});
        return {};
      }
    },
  }));

type ProjectGlobalType = Instance<typeof ProjectGlobalStoreModel>;
export interface ProjectGlobal extends ProjectGlobalType {}

type ProjectGlobalSnapshotType = SnapshotOut<
  typeof ProjectGlobalStoreModel
>;
export interface ProjectGlobalSnapshot extends ProjectGlobalSnapshotType {}
