import { getEnv, IStateTreeNode } from 'mobx-state-tree';
import { Environment } from '../Environment';

/**
 * Adds a environment property to the node for accessing our
 * Environment in strongly typed.
 */
export const EnvironmentExtension = (self: IStateTreeNode) => ({
  views: {
    get environment() {
      return getEnv<Environment>(self);
    },
  },
});
