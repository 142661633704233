import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for Duration
 */
export const DurationStoreModel = types
  .model('DurationStore', {
    date: types.optional(types.string, ''),
    duration: types.optional(types.integer, 0),
    end_time: types.optional(types.integer, 0),
    start_time: types.optional(types.integer, 0),
    waiting: types.optional(types.boolean, false),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setDuration(duration: {
      date: string;
      duration: number;
      end_time: number;
      start_time: number;
      waiting: boolean;
    }) {
      if (duration) {
        self.date = duration.date;
        self.duration = duration.duration;
        self.end_time = duration.end_time;
        self.start_time = duration.start_time;
        self.waiting = duration.waiting;
      }
    },
  }));

/**
 * Store for Durations
 */
export const DurationsStoreModel = types
  .model('DurationsStore', {
    durations: types.array(DurationStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setDurations(durations: any) {
      self.durations = durations;
    },
  }));

type DurationStoreType = Instance<typeof DurationStoreModel>;
export interface DurationStore extends DurationStoreType {}

type DurationStoreSnapshotType = SnapshotOut<typeof DurationStoreModel>;
export interface DurationStoreSnapshot extends DurationStoreSnapshotType {}
