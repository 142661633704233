import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';
import { ResourceTypeStoreModel } from './ResourceType';
import { TimezoneStoreModel } from './Timezone';

/**
 * Store for Resource
 */
export const ResourceStoreModel = types
  .model('ResourceStore', {
    id: types.optional(types.integer, 0),
    color: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    updated_at: types.optional(types.string, ''),
    url: types.optional(types.string, ''),
    resource_type: types.maybe(ResourceTypeStoreModel),
    timezone: types.maybe(TimezoneStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setResource(resource: {
      id?: any;
      color?: any;
      updated_at?: any;
      url?: any;
      resource_type?: any;
      timezone?: any;
    }) {
      if (resource) {
        self.id = resource.id;
        self.color = resource.color;
        self.updated_at = resource.updated_at;
        self.url = resource.url;
        self.resource_type = resource.resource_type;
        self.timezone = resource.timezone;
      }
    },
  }))
  .actions((self) => ({
    async getResource(accountId: string, resourceId: string) {
      const resourceResponse = await self.environment.api.getResource(
        accountId,
        resourceId
      );

      if (resourceResponse) {
        self.setResource(resourceResponse);

        return resourceResponse;
      } else {
        self.setResource({});

        return {};
      }
    },
    async createResource(accountId: string, resource: any) {
      const resourceResponse = await self.environment.api.createResource(
        accountId,
        resource
      );

      if (resourceResponse) {
        self.setResource(resourceResponse);

        return resourceResponse;
      } else {
        self.setResource({});

        return {};
      }
    },
    async updateResource(accountId: string, resourceId: string, resource: any) {
      const resourceResponse = await self.environment.api.updateResource(
        accountId,
        resourceId,
        resource
      );

      if (resourceResponse) {
        self.setResource(resourceResponse);

        return resourceResponse;
      } else {
        self.setResource({});

        return {};
      }
    },
    async deleteResource(accountId: string, resourceId: string) {
      const resourceResponse = await self.environment.api.deleteResource(
        accountId,
        resourceId
      );

      if (resourceResponse) {
        self.setResource({});

        return {};
      } else {
        self.setResource({});

        return {};
      }
    },
  }));

/**
 * Store for Resources
 */
export const ResourcesStoreModel = types
  .model('ResourcesStore', {
    resources: types.array(ResourceStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setResources(resources: any) {
      self.resources = resources;
    },
  }))
  .actions((self) => ({
    async getResources(accountId: string, ids = [], limit = 50, offset = 0) {
      const resourcesResponse = await self.environment.api.getResources(
        accountId,
        ids,
        limit,
        offset
      );

      if (resourcesResponse) {
        self.setResources(resourcesResponse);

        return resourcesResponse;
      } else {
        self.setResources([]);

        return [];
      }
    },
  }));

type ResourceStoreType = Instance<typeof ResourceStoreModel>;
export interface ResourceStore extends ResourceStoreType {}

type ResourceStoreSnapshotType = SnapshotOut<typeof ResourceStoreModel>;
export interface ResourceStoreSnapshot extends ResourceStoreSnapshotType {}
