import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Grid, Typography } from '@material-ui/core';
import * as QueryString from 'query-string';
import { useStores } from '../../../models';
import { AuthContext } from '../../../providers/Auth';

export default () => {
  const history = useHistory();
  const location = useLocation();
  const { authStore } = useStores();
  const authContext = useContext(AuthContext);

  const getCode = useCallback(async () => {
    const params = QueryString.parse(location.search);

    if (params.code) {
      const results = await authStore.authToken(params.code.toString());

      console.log('***', 'OAuth / results', results);

      // TODO: SET ERRORS
      if (results.kind !== 'ok') {
      }

      history.push('/');
      history.go(0);
    }
  }, [authStore, history, location]);

  useEffect(() => {
    getCode();
  }, [getCode]);

  return (
    <Container disableGutters={true} maxWidth={false}>
      <Container maxWidth='lg'>
        <Grid
          alignItems='center'
          container
          justifyContent='center'
          spacing={10}
        >
          <Grid item xs={12}>
            <Typography variant='h1' gutterBottom>
              Authenticating...
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};
