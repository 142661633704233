import { ApisauceInstance, create, ApiResponse } from 'apisauce';
import { getGeneralApiProblem } from './Problem';
import { ApiConfig, DEFAULT_API_CONFIG } from './Config';
import { load, save, remove } from '../../utils/storage';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { isEmpty } from 'ramda';
import * as QueryString from 'query-string';

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  async setup() {
    const me: any = await load('me');
    const accessToken = me ? me['access_token'] : '';
    this.apisauce = create({
      baseURL: this.config.rg_url,
      timeout: this.config.timeout,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    const refreshParameters = {
      client_id: this.config.client_id,
      client_secret: this.config.client_secret,
      code: me ? me['refresh_token'] : '',
      grant_type: 'authorization_code',
      redirect_uri: this.config.redirect_uri,
    };

    const refreshAuthLogic = async (failedRequest: {
      response: { config: { headers: { Authorization: string } } };
    }) => {
      this.apisauce.setBaseURL(this.config.rg_url);
      this.apisauce.axiosInstance
        .post('/oauth/token', QueryString.stringify(refreshParameters))
        .then(async (tokenRefreshResponse: any) => {
          if (isEmpty(me)) {
            return Promise.resolve();
          }
          const meResponse = tokenRefreshResponse.data;
          await save('me', meResponse);
          this.apisauce.headers.Authorization = `Bearer ${tokenRefreshResponse.data.access_token}`;
          failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.access_token}`;
          return Promise.resolve();
        });

      await createAuthRefreshInterceptor(
        this.apisauce.axiosInstance,
        refreshAuthLogic
      );
    }
  }

  async getRGToken(code: string): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url)
    const parameters = {
      client_id: this.config.client_id,
      client_secret: this.config.client_secret,
      code: code,
      grant_type: 'authorization_code',
      redirect_uri: this.config.redirect_uri,
    };

    const response: ApiResponse<any> = await this.apisauce.post(
      `/oauth/token`,
      QueryString.stringify(parameters)
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    this.apisauce.setHeaders({"Authorization": `Bearer ${response.data.access_token}`});

    const accountsResponse: ApiResponse<any> = await this.apisauce.get(
      `/v1/accounts`,
    );

    const me = { ...response.data, "account_id": accountsResponse.data[0].subdomain }

    save('me', me);

    return { kind: 'ok', auth: me };
  }

  async getAccounts(): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(`/v1/accounts`);

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', accounts: response.data };
  }

  async getAccount(accountId: string): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/accounts/${accountId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', account: response.data };
  }

  async getBookings(accountId: string): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/bookings`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', bookings: response.data };
  }

  async getPeople(): Promise<any> {
    this.apisauce.setBaseURL(this.config.url)
    const response: ApiResponse<any> = await this.apisauce.get(
      `/people/`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', people: response.data };
  }

  async getBooking(accountId: string, bookingId: string): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/bookings/${bookingId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', booking: response.data };
  }

  async getBookingsByProject(
    accountId: string,
    projectId: string
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/projects/${projectId}/bookings`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', bookings: response.data };
  }

  async getBookingsByClient(accountId: string, clientId: string): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/clients/${clientId}/bookings`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', bookings: response.data };
  }

  async getBookingsByResource(
    accountId: string,
    resourceId: string
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/resources/${resourceId}/bookings`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', bookings: response.data };
  }

  async createBooking(
    accountId: string,
    booking: Record<
      string,
      string | number | boolean | readonly QueryString.Stringifiable[]
    >
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.post(
      `/v1/${accountId}/bookings`,
      QueryString.stringify(booking)
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', booking: response.data };
  }

  async updateBooking(
    accountId: string,
    bookingId: string,
    booking: Record<
      string,
      string | number | boolean | readonly QueryString.Stringifiable[]
    >
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.put(
      `/v1/${accountId}/bookings/${bookingId}`,
      QueryString.stringify(booking)
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', booking: response.data };
  }

  async deleteBooking(accountId: string, bookingId: string): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/v1/${accountId}/bookings/${bookingId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok' };
  }

  async getDowntimes(
    accountId: string,
    resourceIds = [],
    limit = 50,
    offset = 0,
    from = null,
    to = null
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const params = { 'resource_ids[]': resourceIds, limit, offset, from, to };
    const qs = QueryString.stringify(params);

    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/downtimes?${qs}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', downtimes: response.data };
  }

  async getDowntime(accountId: string, downtimeId: string): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/downtimes/${downtimeId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', downtime: response.data };
  }

  async createDowntime(
    accountId: string,
    downtime: Record<
      string,
      string | number | boolean | readonly QueryString.Stringifiable[]
    >
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.post(
      `/v1/${accountId}/downtimes`,
      QueryString.stringify(downtime)
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', downtime: response.data };
  }

  async updateDowntime(
    accountId: string,
    downtimeId: string,
    downtime: Record<
      string,
      string | number | boolean | readonly QueryString.Stringifiable[]
    >
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.post(
      `/v1/${accountId}/downtimes/${downtimeId}`,
      QueryString.stringify(downtime)
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', downtime: response.data };
  }

  async deleteDowntime(accountId: string, downtimeId: string): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/v1/${accountId}/downtimes/${downtimeId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok' };
  }

  async getDowntimeEventTypes(accountId: string): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/downtime_types`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', downtimeTypes: response.data };
  }

  async getClients(accountId: string, limit = 50, offset = 0): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/clients?limit=${limit}&offset=${offset}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', clients: response.data };
  }

  async getArchivedClients(
    accountId: string,
    limit = 50,
    offset = 0
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/clients/archived?limit=${limit}&offset=${offset}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', clients: response.data };
  }

  async getClient(accountId: string, clientId: string): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/clients/${clientId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', client: response.data };
  }

  async createClient(
    accountId: string,
    client: Record<
      string,
      string | number | boolean | readonly QueryString.Stringifiable[]
    >
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.post(
      `/v1/${accountId}/clients`,
      QueryString.stringify(client)
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', client: response.data };
  }

  async updateClient(
    accountId: string,
    clientId: string,
    client: Record<
      string,
      string | number | boolean | readonly QueryString.Stringifiable[]
    >
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.put(
      `/v1/${accountId}/clients/${clientId}`,
      QueryString.stringify(client)
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', client: response.data };
  }

  async deleteClient(accountId: string, clientId: string): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/v1/${accountId}/clients/${clientId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok' };
  }

  async getMe(): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(`/v1/me`);

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', me: response.data };
  }

  async getProjects(accountId: string, limit = 50, offset = 0): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/projects?limit=${limit}&offset=${offset}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', projects: response.data };
  }

  async getArchivedProjects(
    accountId: string,
    limit = 50,
    offset = 0
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/projects/archived?limit=${limit}&offset=${offset}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', projects: response.data };
  }

  async getProject(accountId: string, projectId: string): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/projects/${projectId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', project: response.data };
  }

  async createProject(
    accountId: string,
    project: Record<
      string,
      string | number | boolean | readonly QueryString.Stringifiable[]
    >
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.post(
      `/v1/${accountId}/projects`,
      QueryString.stringify(project)
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', project: response.data };
  }

  async updateProject(
    accountId: string,
    projectId: string,
    project: Record<
      string,
      string | number | boolean | readonly QueryString.Stringifiable[]
    >
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.put(
      `/v1/${accountId}/projects/${projectId}`,
      QueryString.stringify(project)
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', project: response.data };
  }

  async deleteProject(accountId: string, projectId: string): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/v1/${accountId}/projects/${projectId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok' };
  }

  async getResources(
    accountId: string,
    ids = [],
    limit = 50,
    offset = 0
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const params = { 'ids[]': ids, limit, offset };
    const qs = QueryString.stringify(params);

    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/resources?${qs}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', resources: response.data };
  }

  async reportGetResources(
    accountId: string,
    start_date = null,
    end_date = null,
    limit = 50,
    offset = 0,
    booking_id = null
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const params = { start_date, end_date };
    const qs = QueryString.stringify(params);

    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/reports/resources?${qs}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    const bookingsParams = { start_date, end_date, limit, offset, booking_id };
    const bookingsQs = QueryString.stringify(bookingsParams);

    const bookingsResponse = await this.apisauce.get(
      `/v1/${accountId}/bookings?${bookingsQs}`
    );

    if (!bookingsResponse.ok) {
      const problem = getGeneralApiProblem(bookingsResponse);
      if (problem) return problem;
    }
    
    // Merge both responses

    return { kind: 'ok', resources: response.data };
  }

  async getArchivedResources(
    accountId: string,
    ids = [],
    limit = 50,
    offset = 0
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const params = { 'ids[]': ids, limit, offset };
    const qs = QueryString.stringify(params);

    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/resources/archived?${qs}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', resources: response.data };
  }

  async getResource(accountId: string, resourceId: string): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/resources/${resourceId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', resource: response.data };
  }

  async createResource(
    accountId: string,
    resource: Record<
      string,
      string | number | boolean | readonly QueryString.Stringifiable[]
    >
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.post(
      `/v1/${accountId}/resources`,
      QueryString.stringify(resource)
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', resource: response.data };
  }

  async updateResource(
    accountId: string,
    resourceId: string,
    resource: Record<
      string,
      string | number | boolean | readonly QueryString.Stringifiable[]
    >
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.put(
      `/v1/${accountId}/resources/${resourceId}`,
      QueryString.stringify(resource)
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', resource: response.data };
  }

  async deleteResource(accountId: string, resourceId: string): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/v1/${accountId}/resources/${resourceId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok' };
  }

  async getResourceTypes(accountId: string): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/resource_types`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', resourceTypes: response.data };
  }

  async getResourceType(
    accountId: string,
    resourceTypeId: string
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.rg_url);
    const response: ApiResponse<any> = await this.apisauce.get(
      `/v1/${accountId}/resource_types/${resourceTypeId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', resourceType: response.data };
  }

  async getPeopleAvailable(
    startDate: string|null = null,
    endDate: string|null = null,
    billable: number = 1,
    name: string|null = null,
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.url);

    const params = { start_date: startDate, end_date: endDate, billable, name };
    const qs = QueryString.stringify(params);

    const response: ApiResponse<any> = await this.apisauce.get(
      `/people_available/?${qs}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', resourceType: response.data };
  }

  async getSchedule(
    startDate: string|null = null,
    endDate: string|null = null,
    name: string|null = null,
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.url);

    const params = { start_date: startDate, end_date: endDate, name };
    const qs = QueryString.stringify(params);

    const response: ApiResponse<any> = await this.apisauce.get(
      `/schedule/?${qs}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', resourceType: response.data };
  }

  async getProjectGlobal(
    startDate: string|null = null,
    endDate: string|null = null,
    name: string|null = null,
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.url);

    const params = { start_date: startDate, end_date: endDate, name };
    const qs = QueryString.stringify(params);

    const response: ApiResponse<any> = await this.apisauce.get(
      `/project_global/?${qs}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', resourceType: response.data };
  }

  async getHiring(
    startDate: string|null = null,
    endDate: string|null = null,
  ): Promise<any> {
    this.apisauce.setBaseURL(this.config.url);

    const params = { start_date: startDate, end_date: endDate };
    const qs = QueryString.stringify(params);

    const response: ApiResponse<any> = await this.apisauce.get(
      `/hiring/?${qs}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', resourceType: response.data };
  }
}
