import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Slider,
} from '@material-ui/core';
import { withStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useStores } from '../../../models';
import { isEmpty } from 'ramda';
import { toJS } from 'mobx';
import { ComboBox } from '../../atoms';
import { PageLoader } from '../../organisms';
import moment from 'moment';
import {
  timeConvertHoursToPercent,
  timeConvertMinutesToHours,
} from '../../../helpers/Data';
import { getBusinessHoursForMonth } from '../../../utils/BusinessDays';

// '#fc8e89' red
// '#ffa96f' orange
// '#a6fa85' green
// '#eceef3' blue

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    overflow: 'hidden',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  allocationLow: {
    backgroundColor: '#722F37',
    color: '#FFFFFF',
  },
  allocationFair: {
    backgroundColor: '#ffa96f',
  },
  allocationGood: {
    backgroundColor: '#a6fa85',
  },
  allocationExceeded: {
    backgroundColor: '#fc8e89',
  },
}));

const HtmlTooltip = withStyles((theme: Theme) => ({
  arrow: {
    color: '#2f2f2f',
  },
  tooltip: {
    backgroundColor: '#2f2f2f',
  },
}))(Tooltip);

export default () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [showPercentage, setShowPercentage] = useState(false);
  const [updateMonths, setUpdateMonths] = useState(false);
  const [data, setData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [searchData, setSearchData] = useState('');
  const [updateData, setUpdateData] = useState(false);
  const defaultMonthsDisplay = 6;
  const [defaultFilters, setDefaultFilters] = useState<any>({
    currentDate: moment().format('YYYY-MM-DD'),
    months: defaultMonthsDisplay,
    monthList: [],
  });
  const { scheduleStore } = useStores();

  const getBookingInfo = (indexPerson: any, monthDate: any, workHours: any) => {
    const bookings = Object.entries(indexPerson);
    let bookingCheck = false;
    let convertedTime: Number = 0;
    let hoursByPercent: Number = 0;
    let minutesBooked: any = 0;
    let projects: any = {};
    projects.children = [];

    const bookingArray: any = bookings.filter((item: any) => {
      for (var key in bookings) {
        if (item[key] === 'name' || item[key] === 'type') {
          return false;
        }
      }

      return true;
    });

    const bookingArrayFiltered: any = bookingArray.filter((item: any) =>
      moment(item[0]).isSame(monthDate, 'month')
    );

    if (bookingArrayFiltered[0]) {
      const allBookings: any = Object.entries(bookingArrayFiltered[0][1]);

      allBookings.forEach((booking: any) => {
        const totalMinutes = parseInt(booking[1].duration);
        minutesBooked += totalMinutes;

        if (minutesBooked > 0) {
          projects.children.push({
            name: booking[1].project_name,
            hours: timeConvertMinutesToHours(totalMinutes),
            percentage: timeConvertHoursToPercent(
              timeConvertMinutesToHours(totalMinutes),
              workHours
            ),
          });
        }
      });

      bookingCheck = true;

      convertedTime = timeConvertMinutesToHours(minutesBooked);
      hoursByPercent = timeConvertHoursToPercent(convertedTime, workHours);
    }

    return (
      <TableCell
        align='center'
        padding='default'
        className={mapColorToStatus(hoursByPercent)}
      >
        {bookingCheck ? (
          <HtmlTooltip
            arrow
            title={
              <List dense>
                {projects.children.map(
                  (
                    project: {
                      name: React.ReactNode;
                      hours: React.ReactNode;
                      percentage: React.ReactNode;
                    },
                    index: string | number | null | undefined
                  ) => {
                    return (
                      <ListItem key={index} dense>
                        <ListItemText>
                          {project.name} -{' '}
                          <em>
                            (
                            {showPercentage
                              ? project.percentage + '%'
                              : project.hours}
                            )
                          </em>
                        </ListItemText>
                      </ListItem>
                    );
                  }
                )}
              </List>
            }
          >
            <span className='booking-hours'>
              {showPercentage
                ? timeConvertHoursToPercent(convertedTime, workHours) + '%'
                : convertedTime}
            </span>
          </HtmlTooltip>
        ) : (
          <span className='booking-hours'>
            {convertedTime}
            {showPercentage && '%'}
          </span>
        )}
      </TableCell>
    );
  };

  const mapColorToStatus = (hours: any) =>
    hours < 40
      ? classes.allocationLow
      : hours >= 40 && hours < 85
      ? classes.allocationFair
      : hours >= 105
      ? classes.allocationExceeded
      : classes.allocationGood;

  const getMonthList = useCallback(() => {
    let monthList: any = {};
    monthList.children = [];

    for (let i = 0; i < toJS(defaultFilters.months); i++) {
      const monthShortName = moment().add(i, 'month').format('MMM');
      const monthNumber = moment().add(i, 'month').format('MM');
      const year = moment().add(i, 'month').format('YYYY');
      const yearMonth = moment().add(i, 'month').format('YYYY-MM-DD');

      monthList.children.push({
        name: monthShortName,
        date: yearMonth,
        workHours: getBusinessHoursForMonth(Number(year), Number(monthNumber)),
      });
    }

    setDefaultFilters({
      ...defaultFilters,
      monthList,
    });
  }, [defaultFilters]);

  const getApiData = async () => {
    let startMonth: any = undefined;
    let endMonth: any = undefined;

    if (defaultFilters.monthList.children) {
      startMonth = moment(defaultFilters.monthList.children[0].date)
        .startOf('month')
        .format('YYYY-MM-DD');

      endMonth = moment(
        defaultFilters.monthList.children[
          defaultFilters.monthList.children.length - 1
        ].date
      )
        .endOf('month')
        .format('YYYY-MM-DD');
    }

    const results = await scheduleStore.getSchedule(
      startMonth,
      endMonth,
      searchData
    );

    // TODO: SET ERRORS
    if (results.kind !== 'ok') {
    }

    setData(Object.entries(results.resourceType.people_global));
  };

  const getPeople = async () => {
    await getApiData();
    getMonthList();
  };

  useEffect(() => {
    (async () => {
      if (loading) {
        await getPeople();
        setLoading(false);
      }
    })();
  }, [loading]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const filterDataArray: any = [];

      data.forEach((filter: any[]) => {
        filterDataArray.push(filter[0]);
      });

      setFilterData(filterDataArray);
    }
  }, [data]);

  // useEffect(() => {
  //   if (!isEmpty(data))
  //     console.log('***', 'useEffect || filterData', filterData);
  // }, [filterData]);

  const handleHoursPercentChange = () => {
    setShowPercentage(!showPercentage);
  };

  const handleMonthCountChange = (
    event: React.SyntheticEvent,
    newValue: number | number[]
  ) => {
    if (defaultFilters.months !== newValue && typeof newValue === 'number') {
      setDefaultFilters({
        ...defaultFilters,
        months: newValue,
      });

      setUpdateMonths(true);
    }
  };

  const handleSearchData = (search: string) => {
    setUpdateData(true);
    setSearchData(search);
    return filterData;
  };

  useEffect(() => {
    if (updateMonths) {
      getMonthList();
      setUpdateMonths(false);
      setUpdateData(true);
    }
  }, [updateMonths]);

  useEffect(() => {
    (async () => {
      if (updateData) {
        await getApiData();
      }
    })();
  }, [defaultFilters.monthList.children]);

  useEffect(() => {
    (async () => {
      if (updateData) {
        await getApiData();
      }
    })();
  }, [searchData]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Container disableGutters={true} maxWidth={false}>
      <Container maxWidth='lg'>
        <Grid alignItems='center' container justifyContent='center' spacing={4}>
          <Grid item xs={12}>
            <Typography variant='h1' gutterBottom>
              People Scheduled
            </Typography>
            <Typography variant='subtitle1'>
              This report displays resource allocation by month. Hover on
              allocation for a detailed breakdown.
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <ComboBox
              getSearch={handleSearchData}
              handleCloseChangeBlur={() => {
                setSearchData('');
              }}
              handleChangeClear={() => {
                setSearchData('');
              }}
              startingOptions={filterData}
              textFieldLabel='Search by resource name'
              handleSelectedOption={(filterData) => {
                setSearchData(filterData ? filterData : '');
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <Typography id='continuous-slider' gutterBottom>
              Number of months to display:
            </Typography>

            <Slider
              defaultValue={defaultMonthsDisplay}
              aria-labelledby='month-display-slider'
              valueLabelDisplay='auto'
              onChangeCommitted={handleMonthCountChange}
              marks
              step={1}
              min={1}
              max={12}
            />
          </Grid>

          <Grid item xs={3}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Allocation by:</FormLabel>
              <RadioGroup
                aria-label='allocation'
                name='allocation'
                value={showPercentage}
                onChange={handleHoursPercentChange}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label='Hours'
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label='Percent'
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {!isEmpty(data) && (
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <TableContainer>
                  <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='left' padding='default'>
                          <Typography variant='h6' component='div'>
                            Resource Name
                          </Typography>
                        </TableCell>
                        {toJS(defaultFilters.monthList.children).map(
                          (
                            month: { name: React.ReactNode },
                            index: string | number | null | undefined
                          ) => {
                            return (
                              <TableCell
                                align='center'
                                padding='default'
                                key={index}
                              >
                                <Typography variant='h6' component='div'>
                                  {month.name}
                                </Typography>
                              </TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {data.map((person: any, personIndex: any) => {
                        return (
                          <TableRow key={personIndex}>
                            <TableCell>
                              <Typography variant='subtitle1' component='div'>
                                {person[1].name}
                              </Typography>

                              <Typography variant='caption' display='block'>
                                {person[1].type}
                              </Typography>
                            </TableCell>

                            {toJS(defaultFilters.monthList.children).map(
                              (
                                month: {
                                  date: React.ReactNode;
                                  workHours: React.ReactNode;
                                },
                                monthIndex: string | number | null | undefined
                              ) => {
                                return (
                                  <React.Fragment key={monthIndex}>
                                    {getBookingInfo(
                                      person[1],
                                      month.date,
                                      month.workHours
                                    )}
                                  </React.Fragment>
                                );
                              }
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Container>
    </Container>
  );
};
