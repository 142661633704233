import moment from 'moment-business-days';

function getHolidays() {
  const christmas2020 = '12-25-2020';
  const newYear = '01-01-2021';
  const martinLutherKing = '01-18-2021';
  const washingtonBirthday = '02-15-2021';
  const memorialDay = '05-31-2021';
  const independenceDay = '07-04-2021';
  const laborDay = '09-06-2021';
  const columbusDay = '10-11-2021';
  const veteransDay = '11-11-2021';
  const thanksgiving = '12-25-2021';
  const blackFriday = '12-26-2021';
  const christmas = '12-25-2021';
  return [
    // christmas2020,
    // newYear,
    // martinLutherKing,
    // washingtonBirthday,
    // memorialDay,
    // independenceDay,
    // laborDay,
    // columbusDay,
    // veteransDay,
    // thanksgiving,
    // blackFriday,
    // christmas
  ];
}

moment.updateLocale('us', {
  holidays: getHolidays(),
  holidayFormat: 'MM-DD-YYYY',
});

export function getBusinessHoursForMonth(year: number, month: number) {
  const zeroPad = (num: number, places: number) =>
    String(num).padStart(places, '0');
  const startOfMonth = moment(`${year}${zeroPad(month, 2)}01`).endOf('month');
  return startOfMonth.businessDaysIntoMonth() * 8;
}
