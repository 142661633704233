import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for CustomFieldOption
 */
export const CustomFieldOptionStoreModel = types
  .model('CustomFieldOptionStore', {
    id: types.optional(types.integer, 0),
    value: types.optional(types.string, ''),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setCustomFieldOption(customFieldOption: { id: number; value: string }) {
      self.id = customFieldOption.id;
      self.value = customFieldOption.value;
    },
  }));

type CustomFieldOptionStoreType = Instance<typeof CustomFieldOptionStoreModel>;
export interface CustomFieldOptionStore extends CustomFieldOptionStoreType {}

type CustomFieldOptionStoreSnapshotType = SnapshotOut<
  typeof CustomFieldOptionStoreModel
>;
export interface CustomFieldOptionStoreSnapshot
  extends CustomFieldOptionStoreSnapshotType {}
