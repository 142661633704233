import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core';
import { WrapWithLink } from '../../../hoc';

interface Props {
  class?: string | undefined;
  color?: string | undefined;
  link?: string | undefined;
  size?: number | undefined;
  width?: number | undefined;
  variation: string | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  bars: {
    fill: '#0483AA',
  },
}));

export default (props: Props) => {
  const classes = useStyles();

  return (
    <div className={props.class}>
      <WrapWithLink link={props.link}>
        <SvgIcon
          style={{
            fill: props.color,
            width: props.width,
            fontSize: props.size,
          }}
          viewBox='0 0 1080 335'
        >
          <path
            d='M103.9,125.9c-17.6,0-50.4,0-72.6,22.2c-24,24.1-24,61.4-24,79.4s0,55.3,24.1,79.4c22.2,22.2,55,22.2,72.6,22.2
    s50.4,0,72.6-22.2c24-24.1,24-61.4,24-79.4s0-55.3-24-79.4C154.2,125.9,121.5,125.9,103.9,125.9z M155.5,285.5
    c-13.2,14.8-35.4,14.8-51.6,14.8s-38.4,0-51.6-14.8c-12.6-14.1-13.6-35.5-13.6-58s1-43.9,13.6-58c13.2-14.8,35.4-14.8,51.6-14.8
    s38.4,0,51.6,14.8c12.6,14.1,13.6,35.5,13.6,58S168.1,271.4,155.5,285.5z M326.2,128h-75.4c-5.8,0-16.5,0.9-23.1,7.2
    c-7.5,7.1-7.5,16.8-7.5,22.6v169.1h2.1c1.8,0,3.6,0,5.1,0h2.8c13.3,0,18.4-8.6,20.4-16v-29.4c3.2-3.7,10-10,22-12.7
    c7.8-1.8,15.6-2.7,23.7-3.6c13.1-1.5,27.9-3.2,46-9c30-9.6,40.7-39.3,40.7-63.9c0-23.1-2.8-40.8-16.7-52.6
    C353.9,128.9,339.7,128,326.2,128z M329.5,228c-17.8,4.7-32.6,6.6-45.8,8.3l-13.4,1.8c-7.2,1.1-13.9,3.1-19.9,6.1v-75.6
    c0-8.7,5.3-11.8,9.5-11.8h67.5c12.2,0,17,3.9,18.4,5.6c2.1,2.3,5.5,8.7,5.5,26.3l0.1,2.2c0,1,0,2.1,0,3.2
    C351.6,207.1,349,222.9,329.5,228z M526.1,128.6H387.5l-0.1,1.1c-0.3,4.2-0.8,25,17.8,27.7h54v170.2h10.4l0,0
    c13.4-0.4,18.2-9.5,19.9-17.3V157.4h57.3l0.1-1.1c0.2-3.3,0.3-14.5-6.3-21.7C537.2,130.8,532.3,128.7,526.1,128.6z M577.2,327.6
    h10.4l0,0c13.4-0.4,18.2-9.5,19.9-17.3V185.7h-30.4L577.2,327.6L577.2,327.6z M839.5,126.5c-16.3,0-22.7,11.9-27.3,23
    c0,0-50.2,120.7-58.8,141.4l-58.8-141.4c-4.7-11.1-11-23-27.4-23c-14.7,0-25.4,10.7-25.4,25.4v175.7h10.3c13.6-0.4,18.4-9.9,20-18
    V172.4l55.1,132.5c4.1,9.9,10.4,24.8,26,24.8s21.9-14.9,26-24.8l55.1-132.5v137.9c1.7,7.7,6.5,16.9,19.9,17.3h10.4V151.8
    C864.9,137.1,854.2,126.5,839.5,126.5z M1050.7,148.7c-22.2-22.2-55-22.2-72.6-22.2s-50.4,0-72.6,22.2c-24,24.1-24,61.4-24,79.4
    s0,55.3,24,79.4c22.2,22.2,55,22.2,72.6,22.2s50.4,0,72.6-22.2c24.1-24,24.1-61.4,24.1-79.4C1074.8,210.1,1074.8,172.8,1050.7,148.7
    z M1029.7,286.1c-13.2,14.8-35.4,14.8-51.6,14.8c-16.2,0-38.4,0-51.6-14.8c-12.6-14.1-13.6-35.5-13.6-58s1-43.9,13.6-58
    c13.2-14.8,35.4-14.8,51.6-14.8c16.2,0,38.4,0,51.7,14.8c12.5,14,13.6,35.5,13.6,58C1043.3,250.6,1042.3,272,1029.7,286.1z'
          />
          <path
            className={classes.bars}
            d='M547.4,74.4c-7.9,4.4-10.8,14.3-6.5,22.3s14.3,11,22.3,6.8c1.1-0.5,25.9-13.2,60,0.7c8.5,3.5,18.1-0.6,21.6-9.1
    c3.4-8.5-0.6-18.1-9.1-21.5C586.4,53.5,549,73.6,547.4,74.4z M512,23.8c-8,4.4-10.9,14.4-6.5,22.4s14.4,11,22.4,6.7
    c2.3-1.2,56.5-29.5,130.8,0.8c8.5,3.4,18.1-0.6,21.5-9.1c3.5-8.5-0.6-18.1-9.1-21.6C581.6-13.5,514.8,22.2,512,23.8z M593.2,123.6
    c-3.4,0-9.9,0-14.2,4.4c-4.7,4.7-4.7,12-4.7,15.6c0,3.5,0,10.9,4.7,15.6c4.3,4.3,10.8,4.3,14.2,4.3c3.5,0,9.9,0,14.2-4.3
    c4.7-4.7,4.7-12,4.7-15.6c0-3.5,0-10.9-4.7-15.6C603.1,123.6,596.6,123.6,593.2,123.6z'
          />
        </SvgIcon>
      </WrapWithLink>
    </div>
  );
};
