import React from 'react';
import Link from '@material-ui/core/Link';
import moment from 'moment';

/*
 * Contents:
 *
 * createExtraAPIData
 * createExtraCSSData
 * createLinkData
 * formatStringLink
 * formatStringSpan
 * formatStringStrong
 * ifEmpty
 * maskSensitiveData
 * replaceStringItems
 * scrollToElemement
 * timeConvertMinutesToHours
 */

export function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

export function createExtraAPIData(
  propName,
  propType,
  propDefaultValue,
  propDescription
) {
  return { propName, propType, propDefaultValue, propDescription };
}

export function createExtraCSSData(cssRuleName, cssClass, cssDescription) {
  return { cssRuleName, cssClass, cssDescription };
}

export function createLinkData(linkText, linkValue) {
  return { linkText, linkValue };
}

export function ifEmpty(val) {
  return val || `--`;
}

export function matchItem(arr, val) {
  return arr.some((item) => val === item);
}

export function formatStringLink(string, value, component) {
  if (!value) {
    return string;
  }

  return (
    <>
      {string.split(value).reduce((prev, current, i) => {
        if (!i) {
          return [current];
        }

        return prev.concat(
          <Link href='#' onClick={component} key={value + current}>
            {value}
          </Link>,
          current
        );
      }, [])}
    </>
  );
}

export function formatStringSpan(string, value) {
  if (!value) {
    return string;
  }

  return (
    <>
      {string.split(value).reduce((prev, current, i) => {
        if (!i) {
          return [current];
        }

        return prev.concat(<span key={value + current}>{value}</span>, current);
      }, [])}
    </>
  );
}

export function formatStringStrong(string, value) {
  if (!value) {
    return string;
  }

  return (
    <>
      {string.split(value).reduce((prev, current, i) => {
        if (!i) {
          return [current];
        }

        return prev.concat(
          <strong key={value + current}>{value}</strong>,
          current
        );
      }, [])}
    </>
  );
}

export function maskSensitiveData(string, mask = '*') {
  return Array(string.length + 1).join(mask);
}

export function replaceStringItems(string, replaceWith) {
  return string.replace(/%(\d+)/g, (_, n) => replaceWith[n - 1]);
}

export function removeMatchedItemsFromString(string, matches) {
  return string.replace(new RegExp(matches, 'gi'), '');
}

export function scrollToElemement(event) {
  const anchor = (event.target.ownerDocument || document).querySelector(
    event.currentTarget.dataset.anchor
  );

  if (anchor) {
    anchor.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
}

export const timeConvertHoursToPercent = (timeBooked, timeAvailable) =>
  Math.floor((timeBooked / timeAvailable) * 100);

export const timeConvertMinutesToHours = (time) =>
  moment.duration(time, 'm').asHours();

export const timeConvertHoursToMinutes = (time) =>
  moment.duration(time, 'h').asMinutes();
