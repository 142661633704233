import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';
import { TimezoneStoreModel } from './Timezone';

/**
 * Store for Owner
 */
export const OwnerStoreModel = types
  .model('OwnerStore', {
    name: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    image: types.optional(types.string, ''),
    timezone: types.maybe(TimezoneStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({});

type OwnerType = Instance<typeof OwnerStoreModel>;
export interface Owner extends OwnerType {}

type OwnerSnapshotType = SnapshotOut<typeof OwnerStoreModel>;
export interface OwnerSnapshot extends OwnerSnapshotType {}
