import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for Timezone
 */
export const TimezoneStoreModel = types
  .model('TimezoneStore', {
    name: types.optional(types.string, ''),
    offset: types.optional(types.integer, 0),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setTimezone(timezone: { name: string; offset: number }) {
      self.name = timezone.name;
      self.offset = timezone.offset;
    },
  }));

type TimezoneStoreType = Instance<typeof TimezoneStoreModel>;
export interface TimezoneStore extends TimezoneStoreType {}

type TimezoneStoreSnapshotType = SnapshotOut<typeof TimezoneStoreModel>;
export interface TimezoneStoreSnapshot extends TimezoneStoreSnapshotType {}
