import React from 'react';
import { CircularProgress, Grid, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  centerContent: {
    display: 'flex',
    height: 'calc(100vh - 64px - 48px)',
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.centerContent}>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item xs={12}>
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={4}
          >
            <Grid item xs={12} md={8}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
