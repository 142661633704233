import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppBar, Button, makeStyles, Theme, Toolbar } from '@material-ui/core';
import common from '@material-ui/core/colors/common';
import { Brand } from '../../atoms';
import { useStores } from '../../../models';
import { AuthContext } from '../../../providers/Auth';
import { isEmpty } from 'ramda';
import { DEFAULT_API_CONFIG as config } from '../../../services/api/Config';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginRight: theme.spacing(2),
  },
  titleGrow: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
  },
  navContainer: {
    flexGrow: 1,

    '& > *': {
      margin: theme.spacing(1),
    },
  },
  button: {
    color: common.white,
  },
}));

export default () => {
  const classes = useStyles();
  const { authStore } = useStores();
  const authContext = useContext(AuthContext);

  const history = useHistory();

  const handleLogout = () => {
    authContext.logout(authStore);
    history.push('/');
  };

  const LoginLogoutButton = () => {
    return (
      <>
        {!isEmpty(authContext.user) ? (
          <Button
            disableElevation
            variant='text'
            className={classes.button}
            onClick={handleLogout}
          >
            Logout
          </Button>
        ) : (
          <Button
            disableElevation
            variant='text'
            className={classes.button}
            href={`${config.rg_url}/oauth/authorize?client_id=${
              config.client_id
            }&redirect_uri=${encodeURIComponent(
              config.redirect_uri
            )}&response_type=code`}
          >
            Login
          </Button>
        )}
      </>
    );
  };

  return (
    <AppBar position='sticky' color='secondary'>
      <Toolbar>
        <Brand
          class={isEmpty(authContext.user) ? classes.titleGrow : classes.title}
          variation='light'
          color='white'
          size={50}
          width={150}
          link='/'
        />

        {!isEmpty(authContext.user) && (
          <div className={classes.navContainer}>
            <Button
              disableElevation
              variant='text'
              className={classes.button}
              href='/'
            >
              Projects
            </Button>
            <Button
              disableElevation
              variant='text'
              className={classes.button}
              href='/schedule'
            >
              Schedule
            </Button>
            <Button
              disableElevation
              variant='text'
              className={classes.button}
              href='/available'
            >
              Available
            </Button>
            <Button
              disableElevation
              variant='text'
              className={classes.button}
              href='/hiring'
            >
              Hiring
            </Button>
          </div>
        )}

        <LoginLogoutButton />
      </Toolbar>
    </AppBar>
  );
};
