import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Setup, RootStore, RootStoreProvider } from './models';
import Routes from './Routes';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';
import { TopAppBar } from './components/organisms';
import { AuthProvider } from './providers/Auth';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function App(props: any) {
  const classes = useStyles();
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);

  useEffect(() => {
    Setup().then(setRootStore);
  }, []);

  if (!rootStore) return null;

  return (
    <RootStoreProvider value={rootStore}>
      <Router>
        <AuthProvider>
          <div className={classes.root}>
            <TopAppBar />
            <Toolbar id='back-to-top-anchor' />
            <Routes />
          </div>
        </AuthProvider>
      </Router>
    </RootStoreProvider>
  );
}

export default App;
