import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for People
 */
export const PeopleStoreModel = types
  .model('PeopleStore', {
    id: types.optional(types.integer, 0),
    name: types.optional(types.string, ''),
    bookings: types.optional(types.string, ''),
  })
  .extend(EnvironmentExtension)
  .props({});

type PeopleType = Instance<typeof PeopleStoreModel>;
export interface People extends PeopleType {}

type PeopleSnapshotType = SnapshotOut<typeof PeopleStoreModel>;
export interface PeopleSnapshot extends PeopleSnapshotType {}
