import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useStores } from '../../../models';
import { isEmpty } from 'ramda';
import { PageLoader } from '../../organisms';
import { removeMatchedItemsFromString } from '../../../helpers/Data';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    overflow: 'hidden',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  allocationLow: {
    backgroundColor: '#eceef3',
  },
  allocationFair: {
    backgroundColor: '#ffa96f',
  },
  allocationGood: {
    backgroundColor: '#a6fa85',
  },
  allocationExceeded: {
    backgroundColor: '#fc8e89',
  },
  alignTop: {
    verticalAlign: 'top',
  },
}));

export default () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>([]);
  const { hiringStore } = useStores();

  const getApiData = async () => {
    const results = await hiringStore.getHiring();

    // TODO: SET ERRORS
    if (results.kind !== 'ok') {
    }

    setData(Object.entries(results.resourceType.hiring));
  };

  const getProjects = async () => {
    await getApiData();
  };

  useEffect(() => {
    (async () => {
      if (loading) {
        await getProjects();
        setLoading(false);
      }
    })();
  }, [loading]);

  // useEffect(() => {
  //   if (!isEmpty(data)) console.log('***', 'useEffect || data', data);
  // }, [data]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Container disableGutters={true} maxWidth={false}>
      <Container maxWidth='lg'>
        <Grid
          alignItems='center'
          container
          justifyContent='center'
          spacing={10}
        >
          <Grid item xs={12}>
            <Typography variant='h1'>Hiring</Typography>
            <Typography variant='h2' gutterBottom>
              Unmet Needs
            </Typography>
            <Typography variant='subtitle1'>
              This report shows resources which need to be hired
            </Typography>
          </Grid>

          {!isEmpty(data) && (
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <TableContainer>
                  <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='left' padding='default'>
                          <Typography variant='h6' component='div'>
                            Project Name
                          </Typography>
                        </TableCell>

                        <TableCell align='left' padding='default'>
                          <Typography variant='h6' component='div'>
                            Date
                          </Typography>
                        </TableCell>

                        <TableCell align='left' padding='default'>
                          <Typography variant='h6' component='div'>
                            Needed
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {data.map((project: any, projectIndex: any) => {
                        const resources = Object.entries(project[1].people);

                        return (
                          <TableRow key={projectIndex}>
                            <TableCell className={classes.alignTop}>
                              <Typography variant='subtitle1' component='div'>
                                {project[1].project_name}
                              </Typography>
                            </TableCell>

                            <TableCell className={classes.alignTop}>
                              {resources.map(
                                (resource: any, resourceIndex: any) => {
                                  return (
                                    <Typography
                                      key={resourceIndex}
                                      variant='body1'
                                      component='div'
                                    >
                                      {resource[1].booking_start_date}
                                    </Typography>
                                  );
                                }
                              )}
                            </TableCell>

                            <TableCell className={classes.alignTop}>
                              {resources.map(
                                (resource: any, resourceIndex: any) => {
                                  return (
                                    <Typography
                                      key={resourceIndex}
                                      variant='body1'
                                      component='div'
                                    >
                                      {resource[1].percent}{' '}
                                      {removeMatchedItemsFromString(
                                        resource[1].resource_name,
                                        'Unassigned|Resource'
                                      )}
                                    </Typography>
                                  );
                                }
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Container>
    </Container>
  );
};
